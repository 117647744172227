@import 'src/g_variable.scss';

@font-face {
    font-family: 'Chater';
    font-style: normal;
    font-weight: 400;
    src: local('Chater') url('./fonts/Charter\ Regular.ttf') url('./fonts/Bitstream\ Charter\ Font.otf');
}

.Attention_eight {
    color: #ff5d5d;
    margin-top: 30px;
    font-size: size_mode(1);
}

.btn_send {
    padding: 10px 20px;
    margin-top: 30px;
    width: 40%;
    font-size: 1rem;
}

.FS_wrapper {
    max-width: 60%;
    margin-top: 10px;
}

.wrap_number {
    position: relative;
    display: flex;
    margin-top: 50px;
    justify-content: center;
}

.wrap_number input {
    font-family: 'Chater';
    color: #E8C861 !important;
}

.wrap_number input:focus {
    outline: 0;
}

.wrap_number_first {
    color: $gold;
    font-size: 3rem;
    margin: 0 10px;
}

.input_number {
    position: absolute;
    border: none;
    font-size: 32px;
    text-align: center;
    background-color: transparent;
    outline: none;
}

.SpecalRetreat {
    margin-right: 30px !important;
}

.iHXrTl {
    z-index: 2;
}

.MaskPhone {
    position: absolute;
    z-index: 0;
    left: 12%;
    background: transparent;
    display: flex;
    justify-content: center;

    div {
        width: 32px;
        height: 58px;
        padding: 2px;
        margin: 0 5px;
        font-size: 32px;
        box-sizing: border-box;
    }

    .firstMaskPhone {
        margin: 0 10px;
        font-size: 2.5rem;
    }
}

.special_symbolPhone {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem !important;
    color: $gold;
}

.special_symbolPhoneNone {
    display: none;
}

.shadows {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    box-shadow: 0 0 0 4px rgba($color: #6833AD, $alpha: 0.6);
    padding: 2px;
    border-radius: 5px;
}

.WrapperTarifs {
    margin: 10px;
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
}

.custom_btn_tarifs {
    padding: 10px 20px;
    margin: 10px;
    font-size: 1.2rem;
    min-width: 100px;
    border-radius: 10px;
}
.h9 {
    margin-right: 10px;
    margin-left: 5px;
}

.WrapperTarifs_active {
    box-shadow: 0 0 4px 4px #E8C861;
    background-color: #FFFFFF;
    color: #4C1476;
    font-weight: bold;
}

.PD_border {
    border: 1px solid #fff;
    padding: 10px;
    background: rgba($color: #fff, $alpha: 0.2);
    border-radius: 15px;
    min-width: 70%;
    padding-left: 50px;
}

.PD_area_inputs {
    max-width: 80%;
    margin-top: 20px;
}

.PD_wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
}

.PD__wrapper_item {
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left !important;

    .PDI_title {
        opacity: .8;
    }

    .PDI_title_tarif {
        color: $gold;
        font-weight: bold;
    }

    p {
        text-align: left;
    }
}

.FS_title {
    font-size: 2rem;
    max-width: 60%;
    text-align: center;
    margin-bottom: 20px;
    background: linear-gradient(141.08deg, #cbad59 25.79%, #e0d29a 63.66%, #b58f56 97.07%);
    background-repeat: no-repeat;
    background-origin: content-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.wrapper_content_form input {
    margin: 10px;
    font-size: 1.5rem;
    width: 100%;
    box-sizing: border-box;
}

.PD_wrapper_title {
    margin-bottom: 10px;

    p {
        text-align: center;
    }

    .FS_title {
        max-width: 100%;
    }

    .phone_number_take {
        margin-top: 20px;
        font-size: 2rem;
    }
}

.PD_wrapper_inputs {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

input[type="date"]::-webkit-datetime-edit,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
    color: rgba($color: black, $alpha: .5);
    position: relative;
}

.phonesResults__wrapper {
    border-top: 1px solid $gold;
    max-width: 80%;
    margin: 10px auto;
}

.phonesResults__item {
    border-bottom: 1px solid $purple__opacity;
    padding: 10px;
}

.phonesResultsHeader {
    color: $gold;
    font-size: 1.4rem;
}

.background-number {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.padding-top {
    padding-top: 40px;
}

.link {
    text-decoration: none;
}

.wrap_number input:nth-child(3) {
    margin-right: 20px !important;
}

@media screen and (max-width: 1300px) {
    .custom_btn_tarifs {
        padding: 8px 15px;
        margin: 8px;
        font-size: 1.1rem;
    }

    .display_number {
        height: 50px;
        font-size: 30px;
        margin: 0 3px;
    }

    .wrap_number_first {
        font-size: 2.5rem;
    }

    .WrapperTarifs {
        max-width: 100%;
    }

    .MaskPhone {
        left: 10%;
    }

}

@media screen and (max-width: 1024px) {
    .FS_title {
        max-width: 90%;
        margin: 0;
        font-size: 1.6rem;
    }

    .wrap_number_first {
        font-size: 2.2rem;
    }

    .custom_btn_tarifs {
        padding: 8px 10px;
        margin: 5px;
        font-size: 1rem;
    }

    .wrap_number {
        flex-wrap: wrap;
    }

    .wrapper_content_form input {
        font-size: 1.2rem;
    }

    .PD_wrapper_title {
        .phone_number_take {
            font-size: 1.6rem;
        }
    }

    .PD_border {
        max-width: 80%;
    }

    .phonesResultsHeader {
        font-size: 1.2rem;
    }

}

@media screen and (max-width: 768px) {
    .FS_title {
        max-width: 95%;
        font-size: 1.4rem;
    }

    .custom_btn_tarifs {
        font-size: .9rem;
    }

    .wrap_number {
        margin-top: 20px;
    }

    .WrapperTarifs {
        margin-top: 20px;
        justify-content: start;
    }

    .PD_border {
        padding-left: 10px;
        text-align: center;
    }

    .PD__wrapper_item {
        margin: 10px 0;
        justify-content: inherit;

        p {
            text-align: center;
        }
    }

    .PDI_title,
    .PDI_title_tarif {
        font-size: .9rem;
    }

    .wrapper_content_form input {
        font-size: 1rem;
    }

    .btn_send {
        padding: 10px 15px;
    }

    .phone_number_take {
        font-size: 1.2rem;
    }

    .MaskPhone {
        left: 12%;

        div {
            width: 22px;
            height: 40px;
            padding: 2px;
            margin: 0 2px;
            font-size: 22px;
            box-sizing: border-box;
        }
    }

    .SpecalRetreat {
        margin-right: 20px !important;
    }

    .phonesResultsHeader {
        font-size: 1.1rem;
    }

    .phonesResultsContent {
        font-size: .8rem;
    }

    .phonesResults__wrapper_mini {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 8px;
    }

    .phonesResults__item {
        padding: 8px;
    }

    .wrap_number input:nth-child(3) {
        margin-right: 11px !important;
    }
}

@media screen and (max-width: 600px) {

    .PD_border,
    .PD_area_inputs {
        max-width: 100%;
        margin: 10px;
    }

    .btn_send {
        font-size: .9rem;
        padding: 10px;
    }

    .menu_formblock {
        height: 60px;

        li {
            font-size: bold;
        }
    }

    .logo_form {
        height: 60px !important;
    }

    .Attention_eight {
        font-size: 1rem;
    }
}

@media screen and (max-width: 500px) {
    .PD_wrapper_title .phone_number_take {
        font-size: 1.3rem !important;
    }

    .menu_formblock li {
        font-size: 1rem !important;
    }
}

@media screen and (max-width: 420px) {

    .PDI_title,
    .PDI_title_tarif {
        font-size: .8rem;
    }

    .wrapper_content_form input {
        font-size: .9rem;
    }

    .btn_send {
        font-size: .8rem;
        width: 50%;
    }

    .FS_wrapper {
        max-width: 80%;
    }

    .Attention_eight {
        margin-top: 10px;
    }

    .phonesResults__wrapper_mini {
        grid-template-columns: 1fr 1fr;
        grid-gap: 8px;
    }
}

@media screen and (max-width: 420px) {
    .menu_formblock li {
        font-size: .85rem !important;
    }

    // #number-input-pro {
    //     width: calc((1vw + 1vh)*2.6rem) !important;
    // }
}