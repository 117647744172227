:root {
  --Purple: #4C1476;
  --Yellow: #E8C861;
  --Purple2: #BE73E3;
  --Black: #2C2C2C;
  --Gray: #F3F3F3;
  --Gray2: #C2C2C2;
  --White: #FFFFFF;
  --Red: #E44B4B;
}

p {
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

@font-face {
  font-family: 'Chater';
  font-style: normal;
  font-weight: 400;
  src: local('Chater') url('./fonts/Charter\ Regular.ttf') url('./fonts/Bitstream\ Charter\ Font.otf');
}

.delivery-description-wrapper {
  border: 1px solid var(--Yellow);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 15px 10px;
  color: var(--White);
}

.fixed-whatsapp-icon {
  background-image: url('./png/whatsapp.svg');
  width: 54px;
  height: 54px;
  background-size: 54px 54px;
}

.div_phones {
  display: flex;
  flex-direction: column;
}

@media (min-width: 535px) {

  .button-mobile,
  .button-back {
    width: 512px;
  }

  .advantage-cards {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 535px) {

  .header-content-button {
    padding-bottom: 15px;
  }

  .button-mobile,
  .button-back {
    width: 100%;
  }

  .link-button {
    width: 100%;
  }
}

@media (min-width: 750px) {

  .advantage-cards {
    grid-template-columns: 1fr 1fr;
  }

  .form-pk {
    width: 512px;
  }
}

@media (max-width: 750px) {

  .link-phone {
    font-size: 18px;
  }

  .back-form {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
  }
}

@media (min-width: 1230px) {

  .fixed-whatsapp-icon {
    width: 70px;
    height: 70px;
    background-size: 70px 70px;
  }

  .header-icon-logo {
    width: 60px;
    height: 65px;
    background-size: 60px 65px;
  }

  .header {
    justify-content: center;
  }

  .header-content-button {
    padding-bottom: 38px
  }

  .rewiew-wrapper,
  .about-wrapper {
    padding: 50px 100px;
  }

  .advantage-wrapper {
    padding: 80px 100px;
  }

  .about-grid-column {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 60px
  }

  .link-phone {
    font-size: 24px;
  }

  .header-content {
    margin-top: 100px;
  }

  .h5 {
    font-size: 32px;
    /* margin-bottom: 15px; */
  }

  .h6 {
    font-size: 24px;
    line-height: 28.8px;
  }

  .h7 {
    font-size: 16px;
    line-height: 150%;
    margin-bottom: 5px;
  }

  .h8 {
    font-size: 24px;
    line-height: 28.8px;
  }

  .window-time-fixed {
    width: 300px;
    height: 200px;
  }

  .window-time {
    grid-template-rows: 1.8fr 1fr;
  }

  .header-content-bezlimit {
    font-size: 64px;
  }

  .rewiew-h,
  .about-h,
  .advantage-h {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 100%;
  }

  /* .rewiew-card {
    margin-top: 40px;
  } */

  .card-user {
    font-size: 18px;
  }

  .card-user-rewiew {
    font-size: 16px;
  }

  .rewiew-card-content {
    margin: 0 0 40px 0;
  }

  .tarifffree {
    font-size: 32px;
  }

  .tarifffree-text3 {
    font-size: 16px;
  }

  .about-p {
    font-size: 16px;
  }

  .about-big-p {
    font-size: 64px;
  }

  .about-little-p {
    font-size: 14px;
  }

  .about-image {
    width: 100%;
    /* height: 340px; */
    margin: 20px 20px 40px 0;
  }

  /* .about-images {
    margin-bottom: 140px;
  } */

  .advantage-cards {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .back-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 30px;
  }

  .form-pk {
    width: 512px;
  }

  .form-left-pk {
    width: 415px;
  }

  .back {
    padding: 50px 100px;
  }

  .back-form-h {
    font-size: 32px;
  }

  .back-form-p {
    font-size: 16px;
  }

  .card-h {
    font-size: 24px;
  }

  .footer-content-bezlimit {
    font-size: 30px;
  }

  .footer-content-bezlimit-p {
    font-size: 14px;
  }

  .footer-menu-div-p,
  .footer-work-mode-div-p {
    font-size: 16px;
  }

  .footer-menu-div-h,
  .footer-link-div-h,
  .footer-work-mode-div-h {
    font-size: 18px;
  }

  .footer {
    margin-top: 120px;
  }

  .catalog {
    padding: 0 300px 50px 300px;
    grid-row-gap: 50px;
  }

  .catalog-tarif-h {
    font-size: 24px;
  }

  .catalog-card {
    padding: 35px 25px;
    margin-bottom: 20px;
  }

  .catalog-card-phone {
    font-size: 28px;
  }

  .catalog-card-description-h,
  .catalog-card-description-p {
    font-size: 18px;
  }

  .notification-wrapper {
    width: 40%;
    top: 5%;
    right: 30%;
  }

  .delivery-wrapper {
    padding: 30px 300px;
  }

  .delivery-description-wrapper {
    padding: 15px;
  }

  .details-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 20%;
  }

}

@media (max-width: 1230px) {

  .header-icon-logo {
    width: 32px;
    height: 37px;
    background-size: 32px 37px;
  }

  .header {
    justify-content: space-between;
  }

  .rewiew-wrapper,
  .about-wrapper {
    padding: 50px 10px 30px 10px;
  }

  .advantage-wrapper {
    padding: 30px 20px;
  }

  .about-grid-column {
    display: grid;
    grid-template-columns: 1fr;
  }


  .header-content {
    margin-top: 35px;
  }

  .h5 {
    font-size: 18px;
    /* margin-bottom: 5px; */
  }

  .h6 {
    font-size: 14px;
  }

  .header-content-bezlimit {
    font-size: 32px;
  }

  .rewiew-h,
  .about-h,
  .advantage-h {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
  }

  /* 
  .rewiew-card {
    margin-top: 15px;
  } */

  .card-user {
    font-size: 14px;
  }

  .card-user-rewiew {
    font-size: 12px;
  }

  .rewiew-card-content {
    margin: 0 0 15px 0;
  }

  .tarifffree {
    font-size: 18px;
  }

  .tarifffree-text3 {
    font-size: 14px;
  }

  .about-p {
    font-size: 14px;
  }

  .about-big-p {
    font-size: 48px;
  }

  .about-little-p {
    font-size: 12px;
  }

  .about-image {
    width: 100%;
    /* height: 200px; */
    margin-right: 5px;
    margin-bottom: 20px;
  }

  /* .about-images {
    margin-bottom: 90px;
  } */

  .back {
    padding: 20px 10px;
  }

  .back-form-h {
    font-size: 24px;
  }

  .back-form-p {
    font-size: 14px;
  }

  .back-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card-h {
    font-size: 18px;
  }

  .footer-content-bezlimit {
    font-size: 22px;
  }

  .footer-content-bezlimit-p {
    font-size: 12px;
  }

  .footer-menu-div-p,
  .footer-work-mode-div-p {
    font-size: 14px;
  }

  .footer-menu-div-h,
  .footer-link-div-h,
  .footer-work-mode-div-h {
    font-size: 14px;
  }

  .footer {
    margin-top: 55px;
  }

  .catalog {
    padding: 0 10px 50px 10px;
    grid-row-gap: 30px;
  }

  .catalog-tarif-h {
    font-size: 18px;
  }

  .catalog-card {
    padding: 25px 25px;
    margin-bottom: 10px;
  }

  .catalog-card-phone {
    font-size: 22px;
  }

  .catalog-card-description-h,
  .catalog-card-description-p {
    font-size: 16px;
  }

  .notification-wrapper {
    width: 90%;
    top: 5%;
    right: 5%;
  }

  .delivery-wrapper {
    padding: 30px 10px;
  }

  .h7 {
    font-size: 14px;
    line-height: 120%;
    margin-bottom: 7px;
  }

  .h8 {
    font-size: 20px;
    line-height: 24px;
  }

  .window-time-fixed {
    width: 300px;
    height: 180px;
  }

  .window-time {
    grid-template-rows: 1.6fr 1fr;
  }

  .details-grid {
    grid-template-columns: 1fr 2fr;
  }

}

.header-background {
  background-image: url('/src/png/Back.png');
  background-size: cover;
  background-color: var(--Purple);
  background-position: 23%;
}

.header {
  display: flex;
  flex-direction: row;
}

.header-left-logo {
  color: var(--White);
  margin-top: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-left {
  color: var(--White);
  margin-top: 30px;
  margin-left: 10px;
}

.header-menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  grid-column-gap: 40px;
}

.header-menu-h {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 110%;
  text-decoration: none;
  color: var(--White);
}

.header-menu-h:hover {
  color: var(--Yellow);
}

.icon-menu {
  width: 30px;
  height: 30px;
}

.link-item {
  margin-top: 14px;
  margin-right: 10px;
}

.link-item-pk {
  display: flex;
  flex-direction: row;
  margin-left: 90px;
  align-items: center;
}

.link-phone {
  color: var(--White);
  margin: 8px;
  font-weight: 700;
  font-family: 'Manrope', sans-serif;
  text-align: end;
  white-space: nowrap;
}

.link-icon {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 90px;
}

.link-icon-pk {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.icon {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  margin: 8px;
}

.social-icon {
  /* width: 16px; */
  height: 16px;
  margin: 2px;
}

.icon-whatsapp {
  background-image: url('./icons/Whatsapp.svg');
}

.icon-telegram {
  background-image: url('./icons/Telegram.svg');
}

.icon-vk {
  background-image: url('./icons/VK.svg');
}

.header-content-font {
  color: var(--White);
  text-align: center;
  font-weight: 600;
  font-family: 'Manrope', sans-serif;
}

.header-content-logo-bezlimit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.header-content-bezlimit {
  font-family: 'Chater';
  color: var(--Yellow);
  text-align: center;
}

.header-logo-bezlimit {
  font-family: 'Chater';
  font-size: 27px;
  color: var(--Yellow);
  text-align: center;
}

.header-icon-logo {
  background-image: url('./icons/logo.png');
}

.header-content-button {
  margin: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button-mobile {
  border: 1px solid var(--White);
  border-radius: 10px;
  padding: 20px 40px;
  color: var(--White);
  background: transparent;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  font-family: 'Manrope', sans-serif;
  cursor: pointer;
}

.button-mobile:active {
  background: var(--White);
  color: var(--Purple);

}

.rewiew-wrapper,
.about-wrapper {
  background-color: var(--White);
}


/* .about-images {
  display: flex;
  overflow-x: scroll;
  margin-top: 14px;
} */

.rewiew-card-ava {
  width: 54.89px;
  height: 54.89px;
}

.rewiew-card-stars {
  display: flex;
  flex-direction: row;
  margin-left: -3px;
}

.rewiew-card-star {
  width: 22px;
  height: 22px;
  background-image: url('./icons/star_solid.svg');
  background-size: 22px 22px;
}

.rewiew-card-header {
  display: flex;
  flex-direction: row;
}

.rewiew-card-name-star {
  display: grid;
  grid-template-rows: 1fr 1fr;
  margin-left: 13px;
}

/* .rewiew-card::-webkit-scrollbar,
.about-images::-webkit-scrollbar {
  scrollbar-width: 100px;
  height: 6px;
  background-color: var(--Gray);
  border-radius: 20px;
} */

/* .rewiew-card::-webkit-scrollbar-thumb,
.about-images::-webkit-scrollbar-thumb {
  background-color: var(--Purple2);
  border-radius: 20px;
} */

.rewiew-card-content {
  border: 1px solid var(--Purple2);
  border-radius: 10px;
  padding: 16px;
}

.card-user {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
}

.card-user-rewiew {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  margin-top: 16px;
  text-align: start;
  min-height: 250px;
  overflow: hidden;
}

.tarifffree-wrapper {
  height: 264px;
  /* background-image: url('./png/Back.png'); */
  background-size: cover;
  background-position: 50%;
  margin: 30px 0;

}

.tarifffree {
  color: var(--White);
  text-align: center;
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
  padding-top: 53px;
}

.tarifffree-text3 {
  color: var(--White);
  opacity: 0.67;
  text-align: center;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
  margin: 20px;
}

.tarifffree-content-button {
  margin: 20px 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.video {
  margin: 20px auto;
}

.about-p {
  font-family: 'Manrope', sans-serif;
  width: 100%;
  margin: auto;
  font-weight: 400;
  color: var(--Black);
  line-height: 120%;
  padding-bottom: 30px;
}

.about-big-p {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 300;
  line-height: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 5px;
  border-top: 1px solid var(--Gray2);
}

.about-little-p {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: center;
  color: var(--Gray2);
  margin-bottom: 20px;
}

.advantage-wrapper {
  background: #F8F4FF;
}

.advantage-cards {
  margin-top: 40px;
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.advantage-card {
  margin-bottom: 30px;
}

.card-h {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  text-align: center;
  color: var(--Black);
  padding-bottom: 12px;
}

.card-p {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: var(--Black);
  opacity: 0.5;
}

.card-icon {
  width: 50px;
  height: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto
}

/* .back {
  background-image: url('/src/png/Back.png');
  background-size: cover;
  background-position: inherit;
} */

.back-form-h {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  color: var(--White);
  margin-bottom: 10px;
}

.back-form-p {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: var(--White);
  opacity: 0.7;
  margin-bottom: 20px;
}

.back-form-input {
  width: calc(100% - 40px);
  border: 1px solid var(--Gray2);
  padding: 20px;
  border-radius: 10px;
  background: transparent;
  margin-bottom: 20px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: var(--White);
}

.back-form-input[type=date]:active:-webkit-calendar-picker-indicator {
  color: var(--White);
}

.back-form-input::placeholder {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  opacity: 0.5;
  color: var(--White);
}

.back-form-input:focus {
  border: 1px solid var(--White);
  outline: 0;
}

.button-back {
  border: 1px solid var(--White);
  border-radius: 10px;
  padding: 20px 40px;
  /* width: 100%; */
  color: var(--Purple);
  background: var(--White);
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  font-family: 'Manrope', sans-serif;

}

.button-back:disabled {
  background-color: var(--Gray2);
  border-color: var(--Gray2);
  color: var(--Gray);
}

.button-back:active:enabled {
  background: var(--Purple);
  border-color: var(--Purple);
  color: var(--White);
  cursor: pointer;
}

.back-checkbox-div {
  width: 102%;
  margin-top: 10px;
}

.back-checkbox-p {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: var(--White);
  margin-left: 5px;
}

.back-checkbox-p-yellow {
  color: var(--Yellow);
  margin-left: 3px;
}

.back-checkbox {
  position: absolute;
  z-index: -10;
  opacity: 0;

}

.back-checkbox+.back-checkbox-p {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.back-checkbox+.back-checkbox-p::before {
  content: '';
  display: inline-block;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid var(--Gray2);
  border-radius: 2px;
  margin-right: 0.5em;
  background-color: var(--White);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
  cursor: pointer;
}

.back-checkbox:checked+.back-checkbox-p::before {
  border-color: var(--Purple2);
  background-color: var(--White);
  background-size: 8.78px;
  background-image: url('./icons/check.svg');
}

.back-checkbox:not(:disabled):not(:checked)+.back-checkbox-p:hover::before {
  border-color: var(--Purple2);
}


.footer-bottom {
  margin-top: 20px;
  width: 100%;
  border-top: 1px solid var(--Gray2);
  opacity: 0.3;
}

.footer-bottom-p {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  color: var(--White);
  margin: 20px 0 0 0;
}

.footer-content-logo-bezlimit {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.footer-icon-logo {
  width: 28px;
  height: 32px;
  background-size: 28px 32px;
  background-image: url('./icons/logo.png');
}

.footer-content-bezlimit {
  font-family: 'Chater';
  color: var(--Yellow);
}

.footer-content-bezlimit-p {
  margin-top: 20px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: var(--White);
  opacity: 0.7;
  width: 355px;
}

.footer-div {
  margin-top: 14px;
  display: grid;
  grid-template-columns: 1fr 1fr;

}

.footer-menu-div-h,
.footer-link-div-h,
.footer-work-mode-div-h {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  color: var(--White);
  margin: 16px 0;
}

.footer-menu-div-p,
.footer-work-mode-div-p {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  color: var(--White);
  margin-bottom: 8px;
  text-decoration: none;
  display: block;
}

.footer-link-phone {
  color: var(--White);
  font-size: 16px;
  margin: 8px 0;
  width: 122px;
  font-weight: 700;
  font-family: 'Manrope', sans-serif;
  white-space: nowrap;
}

.hamburger-back {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--Black);
  opacity: 0.65;
  top: 0;
  left: 0;
  z-index: 9;
}

.hamburger {
  position: fixed;
  width: 301px;
  height: 100%;
  background-color: var(--White);
  z-index: 100;

}

.hamburger-button {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: var(--Gray2);
  margin: 40px 20px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.hamburger-menu {
  margin: 96px 0 0 0;
  padding: 0 20px;
  border-bottom: 1px solid #E6E6E6;
  ;
}

.hamburger-menu-h {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: var(--Black);
  padding-bottom: 24px;
  text-decoration: none;
  display: block;
}

.hamburger-link {
  padding: 8px 20px;
}

.hamburger-link-h {
  margin-bottom: 16px;
  margin-top: 16px;
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: var(--Black);
}

.hamburger-link-p {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: var(--Black);
  margin-bottom: 10px;
  text-decoration: none;
  display: block;
  white-space: nowrap;
}

.hamburger-icon {
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  margin: 0 14px 8px 0;

}

.hamburger-link-icon,
.footer-row-pk {
  display: flex;
  flex-direction: row;
}

.footer-grid-link {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 70px;
  margin-left: 150px;
}

.catalog {
  display: grid;
  overflow: hidden;
}

.catalog-tarif-h {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  color: var(--Yellow);
}

.catalog-card-width {
  display: block;
  padding-right: 50px;
  width: 100%;
}

.catalog-card {
  border: 1px solid var(--Gray2);
  border-radius: 8px;
  /* width: 100%; */
}

.catalog-card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
}

.catalog-card-phone {
  color: var(--White);
}

.card-icon-chevron {
  color: var(--White);
  width: 30px;
  height: 30px;
}

.catalog-card-descriptions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;
  margin-top: 20px;
}

.catalog-card-description-h {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  color: var(--Gray2);
  margin-bottom: 10px;
}

.catalog-card-description-p {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  color: var(--White);
  margin-bottom: 5px;
}

.catalog-card-button {
  margin: 20px 0 ;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.button-catalog {
  border: 1px dashed var(--Gray2);
  border-radius: 10px;
  padding: 20px 40px;
  color: var(--White);
  background: transparent;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  font-family: 'Manrope', sans-serif;
  cursor: pointer;
  width: 100%;
}

.notification-wrapper {
  position: fixed;
  background-color: var(--White);
  border-radius: 10px;
  z-index: 10;
}

.notification-icon {
  position: absolute;
  width: 24px;
  height: 24px;
  color: var(--Black);
  margin: 10px;
  right: 0;
  cursor: pointer;
}

.notificarion-h {
  font-size: 18px;
  font-weight: 700;
  font-family: 'Manrope', sans-serif;
  text-align: center;
  margin-top: 30px;
}

.notification-phone {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  color: var(--Purple);
  font-family: 'Chater';
}

.notification-price {
  font-size: 36px;
  font-weight: 500;
  text-align: center;
  color: var(--Black);
  font-family: 'Chater';
  margin-right: 3px;
}

.notification-header-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.notification-p {
  font-size: 18px;
  font-weight: 700;
  font-family: 'Manrope', sans-serif;
}

.notification-grid {
  display: grid;
  grid-row-gap: 15px;
  padding: 5px 20px 15px 20px;
}

.notification-detail-p {
  font-size: 14px;
  font-weight: 700;
  font-family: 'Manrope', sans-serif;
  margin-bottom: 5px;
}

.notification-description {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;
}

.notification-description-h {
  font-size: 18px;
  font-weight: 600;
  font-family: 'Manrope', sans-serif;
  color: var(--Gray2);
}

.notification-description-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.notification-description-grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.notification-description-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-notification {
  border: none;
  border-radius: 10px;
  padding: 20px 40px;
  color: var(--White);
  background-color: var(--Purple);
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  font-family: 'Manrope', sans-serif;
  cursor: pointer;
  width: 100%;
}

.button-notification:active {
  background-color: var(--Yellow);
}

.button-notification-wrapper {
  padding: 0 30px;
}

input[type='radio']:after {
  width: 14px;
  height: 14px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: var(--White);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='radio']:checked:after {
  width: 14px;
  height: 14px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: var(--Purple2);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.delivery-radio-div {
  margin: 0 0 20px 0;
  display: grid;
  grid-row-gap: 10px;
}

.margin-bottom {
  margin-bottom: 30px;
}

.order-p {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Manrope', sans-serif;
  text-align: center;
}

.order-grid-p {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Manrope', sans-serif;
}

.order-icon {
  position: absolute;
  width: 90%;
  height: 70px;
  color: var(--Purple);
  margin: 50px auto;
}

.div-center {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.Index_form .div-center {
  padding: 0 10px;
}

.btn {
  outline: none;
  cursor: pointer;
  border-radius: 20px;
  color: #000;
  transition: .8s;
  border: 1px solid var(--White);
  color: var(--White);
  background: transparent;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Manrope', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn:active {
  transition: .4s;
  color: var(--Purple);
}

.btn .icon {
  margin: 0;
  margin-right: 3px;
}

.details-grid {
  display: grid;
}

.p10 {
  font-size: 11px;
}

a {
  cursor: pointer;
}

.wrap_number {
  position: relative;
  display: flex;
  margin: 20px 0;
  justify-content: center;

}

.swiper {
  padding: 30px 0 !important;
}

.swiper-slide {
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: .15 !important;
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: var(--swiper-navigation-sides-offset, 45%) !important;
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: var(--swiper-navigation-sides-offset, 45%) !important;
}

.swiper-button-next,
.swiper-button-prev {
  top: var(--swiper-navigation-top-offset, 92%) !important;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after,
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  color: var(--Purple);
}

.swiper-wrapper {
  align-items: center;
}

.fixed-whatsapp {
  position: fixed;
  bottom: 3%;
  right: 2%;
  z-index: 8;
}

.back-button {
  border: none;
  border: 1px solid var(--White);
  border-radius: 10px;
  padding: 20px;
  color: var(--White);
  background: transparent;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  font-family: 'Manrope', sans-serif;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.back-button .card-icon-chevron {
  padding-right: 15px;
}

.back-button-flex {
  display: block;
  padding: 20px;
}

.flex {
  display: flex;
  justify-content: center;
}

.window-time-fixed {
  position: fixed;
  right: 2%;
  bottom: 0;
  z-index: 10;
}

.opacity-background {
  background-color: var(--Yellow);
  opacity: 0.95;
  width: 100%;
  height: 100%;
}

.window-time {
  position: absolute;
  top: 0;
  padding: 20px;
  padding-top: 30px;
  display: grid;
}

input::placeholder {
  transition: 0.5s;
  /* Время трансформации */
  color: #aaa;
  /* Цвет подсказки */
}

input:focus::placeholder {
  font-size: 10px;
  /* Размер шрифта */
  transform: translateY(-16px);
  /* Сдвигаем вверх */
}

.catalog-left-logo {
  color: var(--White);
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.error-tel {
  position: absolute;
  top: 5px;
  left: 20px;
  color: #ff4000;
}

.div-error-tel {
  position: relative;
}